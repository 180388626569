<template>
  <div class="default-layout background">
    <the-header />

    <v-main>
      <router-view />
    </v-main>

    <the-footer />
  </div>
</template>

<script>
import TheHeader from "@/components/shared/headers/TheHeader";
import TheFooter from "@/components/shared/footers/TheFooter";

export default {
  name: "LimeLoansDefault",

  components: { TheHeader, TheFooter },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.default-layout {
  min-height: 100%;
}
</style>
